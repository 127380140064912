exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-bigdata-analytics-js": () => import("./../../../src/pages/bigdata-analytics.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

